import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import { useLayout } from "contexts/components/LayoutContext"
import { useLogos } from "hooks/queries/settings/useLogos"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { GET_SERVICE_PAGES_GRAPHQL } from "./local-business-schema"

interface LocalBusinessSchemaProps {
  serviceArea: string
  richDataLink?: string
  richDataImageUrl: string
}

export const LocalBusinessSchema = ({
  serviceArea,
  richDataLink = "",
  richDataImageUrl,
}: LocalBusinessSchemaProps) => {
  const { servicePages } =
    useStaticQuery<GET_SERVICE_PAGES_GRAPHQL>(getServicePages)

  const { companyName, formattedOffices, siteUrl, socialMedia } =
    useGeneralSettings()
  const { headerLogo } = useLogos()
  const { breadcrumbLocation } = useLayout()
  const { businessHours, priceRange } = formattedOffices[0]

  const getUrl = () => {
    const slugWithoutForwardSlash = breadcrumbLocation?.startsWith("/")
      ? breadcrumbLocation.slice(1)
      : breadcrumbLocation

    const url = siteUrl?.endsWith("/")
      ? `"${siteUrl}${slugWithoutForwardSlash}"`
      : `"${siteUrl}/${slugWithoutForwardSlash}"`

    return url
  }

  const getOpeningHours = () => {
    return businessHours.map(({ elements }) => {
      const { day_of_the_week, open, close } = elements
      return `{"@type":"OpeningHoursSpecification","dayOfWeek":["${day_of_the_week?.value[0]?.name}"], "opens":"${open.value}", "closes":"${close.value}"}`
    })
  }

  const getListElement = () => {
    if (servicePages?.nodes?.length === 0) return ""

    return servicePages.nodes.map(({ elements }) => {
      const { pageTitle, slug } = elements
      const url = slug.value.startsWith("/")
        ? `${siteUrl}${slug.value}`
        : `${siteUrl}/${slug.value}`
      return `{
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "${pageTitle.value}",
                  "url": "${url}"
                }
              }`
    })
  }

  const getSocialMediaList = () => {
    if (socialMedia.length === 0) return ""

    return `"sameAs": [ ${socialMedia.map(social => {
      const { linkUrl } = social
      return `"${linkUrl}" `
    })}],`
  }
  return (
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context":"https://schema.org",
            "@type":"LocalBusiness",
            "name":"${companyName}",
            ${getSocialMediaList()}
            "logo":"${headerLogo.url}",
            "areaServed": [
              {"@type": "City", "name": "${serviceArea}", "@id": "${richDataLink}" }
            ],
            "image":"${richDataImageUrl}",
            "priceRange":"${priceRange}",
            "url":${getUrl()},
            "openingHoursSpecification":[
              ${getOpeningHours()}
            ],
            "hasOfferCatalog": {
              "@type": "OfferCatalog",
              "itemListElement": [
                ${getListElement()}
              ]
            }
          }
        `}
      </script>
    </Helmet>
  )
}

export const getServicePages = graphql`
  query GET_SERVICE_PAGES {
    servicePages: allKontentItemPage(
      filter: {
        elements: {
          is_service_page: { value: { elemMatch: { codename: { eq: "yes" } } } }
        }
      }
    ) {
      nodes {
        id
        elements {
          slug {
            value
          }
          pageTitle: seo_metadata__meta_title {
            value
          }
        }
      }
    }
  }
`
