import React from "react"
import { Banner } from "./HeroBanner-types"
import "./hero.module.scss"
import "./hero-background-video.module.scss"
import { useColorAreaSettings } from "hooks/useColorAreaSettings"
import { getBackgroundImageUrlByBreakpoint } from "assets/helper/getBackgroundImageUrlByBreakpoint"
import { HeroContent } from "./HeroContent"
import { BackgroundVideo } from "components/background-video"
import { BackgroundVideoPlayButton } from "components/background-video/BackgroundVideoPlayButton"

interface HeroProps {
  banner: Banner
}

export const GenericHero = ({ banner }: HeroProps) => {
  const {
    background_image,
    heading,
    sub_heading,
    buttons,
    video_background_url,
  } = banner

  const { textBannerBackgroundColor, imageBannerColorOverlay } =
    useColorAreaSettings()

  const backgroundImageCSSVariable = background_image?.value[0]?.url
    ? getBackgroundImageUrlByBreakpoint({
        variableName: "hero-background-image",
        imageUrl: background_image.value[0].url,
        breakpoints: {
          mobile: {
            width: 768,
          },
        },
      })
    : {}

  const isTextOnly =
    background_image && background_image.value.length > 0 ? false : true

  const bannerColorOverride = textBannerBackgroundColor
    ? `${textBannerBackgroundColor}--backgroundColor`
    : ``
  const overrideBackground = imageBannerColorOverlay
    ? `${imageBannerColorOverlay}--backgroundOverlayBefore`
    : ""

  return (
    <section
      className={`${
        isTextOnly
          ? `fr-hero--default-background-color ${bannerColorOverride}`
          : "fr-hero--medium-height"
      } ${overrideBackground}`}
      style={backgroundImageCSSVariable}
    >
      <BackgroundVideo
        hasBackgroundImage={!!background_image?.value[0]?.url}
        componentName="fr-generic-hero"
        url={video_background_url?.value}
      >
        <div
          className={
            isTextOnly
              ? `fr-hero__banner-content--padding-large fr-container`
              : `fr-hero__banner-content fr-container`
          }
        >
          <HeroContent
            baseClassName="fr-hero__banner-content"
            heading={heading?.value}
            subHeading={sub_heading}
            buttons={buttons?.value}
          >
            {video_background_url?.value && <BackgroundVideoPlayButton />}
          </HeroContent>
        </div>
      </BackgroundVideo>
    </section>
  )
}
